.App {
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
  /* min-height: calc(100vh - 1.5rem); */
  min-height: 100vh;
  padding-bottom: 5rem;
}

.App .choices {
  margin: 5px 0;
  font-size: 0.7rem;
  text-align: center;
}

.App .choices strong:not(:first-child) {
  margin-left: 5px;
}

header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  color: #fff;
  margin-bottom: 10px;
}

header.loading {
  background: #0a61fe;
}

.buttons.location {
  display: flex;
  flex-wrap: wrap;
}

.buttons:not(.location) > div {
  background: #0a61fe;
  color: #fff;
  width: 100%;
  padding: 2rem 0;
  font-size: 1.5rem;
  margin-bottom: 10px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}

.buttons > div.working {
  background: #333;
  opacity: .5;
  cursor: not-allowed;
}

.buttons.location > div {
  flex: 0 0 100%;
  max-width: 100%;
}

.buttons.location > div.half {
  flex: 0 0 50%;
  max-width: 50%;
}

.buttons.location > div > div {
  background: #0a61fe;
  color: #fff;
  width: calc(100% - 10px);
  padding: 2rem 0;
  font-size: 1.5rem;
  margin-bottom: 10px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}

.stanowisko {
  display: flex;
}

.stanowisko_input {
  width: 100%;
  font-size: 2rem;
  padding: 2rem 0;
}

footer {
  background: #282c34;
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

footer button.prevStep {
  background: #fff;
  outline: 0;
  border: 0;
  font-size: 0.8rem;
  margin-left: 15px;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

footer button.prevStep > span {
  display: block;
  font-size: 0.6rem;
}

footer.home {
  align-items: center;
  justify-content: space-between;
}

footer .footerVersion {
  color: #FFF;
  font-size: 0.7rem;
  margin-right: 15px;
  font-style: italic;
}

footer .footerButtons {
  display: flex;
}

.lista_header, .lista {
  display: flex;
  border: 1px solid #000;
  border-bottom: 0;
  flex-wrap: wrap;
  width: 100%;
}

.lista_header > div{
  width: 100%;
  display: flex;
}

.lista_header > div > div {
  width: 20%;
  text-align: center;
  border-right: 1px solid #000;
  padding: 10px;
}

.lista_header.spakowane {
  display: none;
}

.lista_header.spakowane > div > div {
  width: 16.666666%;
}

.lista > div {
  width: 100%;
  display: flex;
  align-items: stretch;
  border-bottom: 1px solid #000;
  flex-wrap: wrap;
}

.lista > div > div {
  width: 20%;
  max-width: 20%;
  /* border-right: 1px solid #000; */
  /* padding: 50px 20px; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  overflow: hidden;
}

.lista > div > div.order_sn, .lista > div > div.product_info, .lista.spakowane > div > div.order_sn, .lista.spakowane > div > div.product_info, .lista > div > div.exceededInfo {
  width: 100%;
  max-width: 100%;
  padding: 10px 5px;
}

/* .lista > div > div.order_sn {
  margin-bottom: 30px;
} */
.lista > div > div.product_info > div, .lista.spakowane > div > div.product_info > div  {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.lista > div > div.product_info > div > div, .lista.spakowane > div > div.product_info > div > div {
  width: 33%;
}
.lista > div > div.product_info > div img {
  max-width: 100%;
  width: 100%;
}

.lista > div > div.exceededInfo {
  color: #990000;
  font-size: 0.7rem;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  padding: 10px 5px 0;
}

.lista > div > div.exceededInfo span {
  font-weight: bold;
  font-size: 0.8rem;
}

.lista > div > div.order_note {
  width: 100%;
  max-width: 100%;
  display: flex;
  margin-bottom: 10px;
  color: #990000;
}

.lista > div.exceeded {
  background: #ffcccc;
}

.lista > div.dhlups {
  background: #ffffd8;
}

.lista.spakowane > div {
  position: relative;
}

.lista.spakowane > div > button {
  background: #333;
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  cursor: pointer;
}
.lista.spakowane > div > button img {
  width: 25px;
  height: 30px;
}

@media (min-width: 979px) {
  .lista.spakowane > div > button img {
    width: 45px;
    height: 50px;
  }
}

.lista.spakowane > div > div {
  width: 16.666666%;
  max-width: 16.666666%;
}

.lista > div > div > div {
  width: 100%;
  margin-bottom: 30px;
}

.lista > div > div > div:last-child {
  margin-bottom: 0;
}

.lista_header > div > div:last-child, .lista:not(.spakowane) > div > div:nth-child(5) {
  border-right: 0;
}

.lista.spakowane > div > div:nth-child(6) {
  border-right: 0;
}

.lista .order_sn svg {
  max-width: 100%;
}

.lista.spakowane .order_courier {
  font-size: 2em;
}

.lista .product_name {
  font-size: 1em;
}

.lista .product_size {
  font-size: 2.5em;
  text-transform: uppercase;
  overflow: hidden;
}
.lista .product_size2 {
  font-size: 1.2em;
  text-transform: uppercase;
  overflow: hidden;
}

.lista .product_quantity {
  font-size: 2.5em;
}

.lista .product_icon img {
  max-width: 100%;
}

.lista .product_icon .multiple_images img {
  max-width: 60%;
}

.lista .product_verify {
  width: 100% !important;
  max-width: 100% !important;
  background-color: rgb(40,126,12);
  color: #fff;
  text-align: center;
  padding: 5px;
  justify-content: center;
  font-size: 1.5em;
  text-transform: uppercase;
  cursor: pointer;
}

.sorting {
  margin-bottom: 10px;
}

.sorting button.sortingSwtich {
  background: #333;
  color: #FFF;
  outline: 0;
  border: 1px solid #333;
  font-size: 0.8rem;
  margin-right: 15px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sorting button.sortingSwtich span {
  font-size: 0.8rem;
  font-weight: bold;
}

.sortingChoose {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #FFF;
  display: flex;
  flex-direction: column;
}

.sortingChoose h4 {
  text-align: center;
  font-size: 1.2rem;
}

.sortingChoose ul {
  list-style: none;
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  overflow-y: auto;
}

.sortingChoose ul > li {
  background: #333;
  color: #FFF;
  padding: 30px;
  border: 1px solid #FFF;
  text-align: center;
}

.popup {
  position: fixed;
  left: 50%;
  width: 90%;
  top: 50%;
  background: #fff;
  transform: translate(-50%,-50%);
  height: 90%;
  border: 1px solid #000;
  overflow: hidden;
  z-index: 999;
}

.popup h2 {
  font-weight: 400;
  margin-left: 30px;
  margin-right: 30px;
  position: relative;
  margin-top: 30px;
  font-size: 1em;
}

.popup h2::after {
  content: '';
  width: 100%;
  height: 1px;
  background: #ddd;
  position: absolute;
  bottom: -10px;
  left: 0;
}

.popup > div {
  margin-left: 30px;
  margin-right: 30px;
  display: flex;
  flex-wrap: wrap;
}

.popup > div.code_text {
  margin-top: 40px;
  text-transform: uppercase;
}

.popup input.code_input {
  margin-top: 10px;
  width:100%;
  height: 40px;
  border: 1px solid #ddd;
  border-radius: 0;
}

.popup input.code_input:focus {
  outline: 0;
  border: 1px solid rgb(40,126,12);
  border-radius: 0;
}

.popup button.code_button {
  height: 43px;
  background: rgb(40,126,12);
  outline: none;
  border: 1px solid rgb(40,126,12);
  color: #fff;
  padding: 0 20px;
  font-size: 0.75rem;
  text-transform: uppercase;
  width: 100%;
  cursor: pointer;
}

.popup > div.code_info {
  font-size: 0.7rem;
}

.popup > .close_popup {
  position: absolute;
  top: 5px;
  right: 0;
  cursor: pointer;
}

.popup > .close_popup > div {
  height: 50px;
  width: 50px;
  position: relative;
}

.popup > .close_popup > div:after {
  content: '';
  height: 25px;
  border-left: 2px solid #333;
  position: absolute;
  transform: rotate(45deg);
  left: 33px;
}

.popup > .close_popup > div:before {
  content: '';
  height: 25px;
  border-left: 2px solid #333;
  position: absolute;
  transform: rotate(-45deg);
  left: 33px;
}

.popup .popup_products {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  font-size: 0.8rem;
  text-transform: uppercase;
  overflow-y: auto;
  max-height: 45vh;
}

.popup .popup_products .popup_product {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  width: 100%;
}

.popup .popup_products .popup_product.done {
  background-color: rgba(40,126,12,0.3);
}

.popup .popup_products .popup_product > div {
  max-width: 25%;
  width: 25%;
}

.popup .popup_products .popup_product > div:nth-child(3) {
  text-align: right;
}

.popup .popup_products .popup_product > div:nth-child(2), .popup .popup_products .popup_product > div:last-child {
  text-align: center;
}

.popup .popup_products .popup_product > div img {
  max-width: 100%;
  width: 30%;
}

.success, .warning {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgb(40,126,12);
  color: #fff;
  z-index: 99999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  text-align: center;
}

.warning {
  background: red;
}

.red {
  color: red;
  font-size: 1.2rem;
}
