.LoginPage {
    position: relative;
    min-height: 100vh;
    padding-bottom: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    background: #a5794c;
}

.LoginPage > div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.LoginHeader {
    font-size: 2rem;
    margin: 2rem 0;
    color: #FFF;
}

.LoginPage img {
    max-width: 400px;
    width: 70%;
    filter: brightness(0) invert(1);
}

.LoginPage input {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    font-size: 2rem;
    padding: 2rem 0;
    border: 0;
}

.LoginError {
    background: red;
    color: #FFF;
    font-size: 3rem;
}

.LoginError button {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: 0;
    background: #FFF;
    margin-top: 1rem;
    font-size: 2rem;
    width: 100%;
    padding: 20px 0;
}